@import 'variables';
@import 'slim';
@import "../../../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

body {
  font-size: 14px;
  overflow-y: hidden;
}

/*********************/
/** STATISTICS
/*********************/

.card-profile-name {
  color: #343a40;
  font-weight: 400; }

.card-profile-position {
  margin-bottom: 5px;
  font-size: 15px;
  color: #495057;
  line-height: 1.7; }

.card-profile-direct {
  color: #adb5bd;
  padding: 12px 20px; }
  .card-profile-direct:hover, .card-profile-direct:focus {
    color: #495057; }

.card-connection {
  padding: 25px; }
  .card-connection .row {
    align-items: center; }
  .card-connection .col-4 {
    font-size: 40px;
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
    text-align: center; }
  .card-connection hr {
    border-top: 1px solid #dee2e6;
    margin: 15px 0; }

.card-people-list {
  padding: 25px; }
  .card-people-list .media-list {
    margin-top: 25px; }
  .card-people-list .media-body {
    margin-left: 15px; }
  .card-people-list .media {
    align-items: center;
    padding: 15px 0; }
    .card-people-list .media + .media {
      border-top: 1px solid #dee2e6; }
    .card-people-list .media:first-child {
      padding-top: 0; }
    .card-people-list .media:last-child {
      padding-bottom: 0; }
  .card-people-list img {
    width: 45px;
    border-radius: 100%; }
  .card-people-list a {
    display: block; }
  .card-people-list p {
    margin-bottom: 0; }

.nav-activity-profile {
  display: block; }
  @media (min-width: 768px) {
    .nav-activity-profile {
      display: flex; } }
  .nav-activity-profile .nav-item + .nav-item {
    margin-top: -1px; }
  @media (min-width: 768px) {
    .nav-activity-profile .nav-item {
      flex: 1; }
      .nav-activity-profile .nav-item + .nav-item {
        margin-top: 0;
        margin-left: -1px; } }
  .nav-activity-profile .nav-link {
    display: block;
    text-align: center;
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #495057;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1px; }
    .nav-activity-profile .nav-link:hover, .nav-activity-profile .nav-link:focus {
      background-color: #f8f9fa; }
  .nav-activity-profile .icon {
    line-height: 0;
    font-size: 24px;
    margin-right: 10px; }

.card-latest-activity .card-body {
  padding: 25px; }

.card-latest-activity .media-author {
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px; }
  .card-latest-activity .media-author img {
    width: 40px;
    border-radius: 100%; }
  .card-latest-activity .media-author .media-body {
    margin-left: 15px; }
    .card-latest-activity .media-author .media-body h6 {
      margin-bottom: 2px;
      font-size: 14px; }
    .card-latest-activity .media-author .media-body p {
      margin-bottom: 0; }
  .card-latest-activity .media-author span {
    font-size: 12px;
    display: none; }
    @media (min-width: 480px) {
      .card-latest-activity .media-author span {
        display: block; } }

.card-latest-activity .post-wrapper {
  padding: 25px;
  background-color: #f8f9fa; }

.card-latest-activity .activity-text {
  margin-bottom: 25px;
  color: #495057; }

.card-latest-activity .activity-title {
  display: block;
  font-size: 24px;
  color: #343a40;
  margin-bottom: 20px;
  line-height: 1.4; }

.card-latest-activity .card-footer {
  border-top: 1px solid #dee2e6;
  padding: 0; }
  .card-latest-activity .card-footer a {
    display: inline-block;
    border-right: 1px solid #dee2e6;
    padding: 12px 15px; }
    @media (min-width: 576px) {
      .card-latest-activity .card-footer a {
        padding: 12px 25px; } }

.card-experience .card-body {
  padding: 25px; }

.card-experience .media {
  margin-top: 25px;
  display: block; }
  @media (min-width: 576px) {
    .card-experience .media {
      align-items: center;
      display: flex; } }

.card-experience .experience-logo {
  display: flex;
  width: 80px;
  height: 80px;
  background-color: #6c757d;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 48px; }

.card-experience .media-body {
  margin-top: 15px; }
  @media (min-width: 576px) {
    .card-experience .media-body {
      margin-left: 25px;
      margin-top: 0; } }

.card-experience .position-name {
  color: #343a40;
  font-size: 15px;
  margin-bottom: 5px; }

.card-experience .position-company {
  margin-bottom: 5px;
  color: #495057; }

.card-experience .position-year {
  margin-bottom: 0;
  font-size: 12px; }

.card-experience .card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dee2e6;
  padding: 0; }
  .card-experience .card-footer a {
    padding: 12px 25px; }
    .card-experience .card-footer a:first-child {
      border-right: 1px solid #dee2e6; }
    .card-experience .card-footer a:last-child {
      border-left: 1px solid #dee2e6; }

[dir="rtl"] .card-profile .media-body {
  text-align: right; }
  @media (min-width: 768px) {
    [dir="rtl"] .card-profile .media-body {
      margin-left: 0;
      margin-right: 30px; } }

[dir="rtl"] .card-profile .card-footer > div a {
  border-left: 0;
  border-right: 1px solid #dee2e6; }
  [dir="rtl"] .card-profile .card-footer > div a:first-child {
    border-right-width: 0; }
    @media (min-width: 768px) {
      [dir="rtl"] .card-profile .card-footer > div a:first-child {
        border-right-width: 1px; } }

@media (min-width: 768px) {
  [dir="rtl"] .nav-activity-profile .nav-item + .nav-item {
    margin-left: 0;
    margin-right: -1px; } }

[dir="rtl"] .nav-activity-profile .icon {
  margin-right: 0;
  margin-left: 10px; }

[dir="rtl"] .card-latest-activity {
  text-align: right; }
  [dir="rtl"] .card-latest-activity .media-author .media-body {
    margin-left: 0;
    margin-right: 15px; }
  [dir="rtl"] .card-latest-activity .card-footer a {
    display: inline-block;
    border-right: 0;
    border-left: 1px solid #dee2e6; }

[dir="rtl"] .card-experience {
  text-align: right; }
  @media (min-width: 576px) {
    [dir="rtl"] .card-experience .media-body {
      margin-left: 0;
      margin-right: 25px; } }
  [dir="rtl"] .card-experience .card-footer a:first-child {
    border-right: 0;
    border-left: 1px solid #dee2e6; }
  [dir="rtl"] .card-experience .card-footer a:last-child {
    border-left: 0;
    border-right: 1px solid #dee2e6; }

[dir="rtl"] .card-recommendation {
  text-align: right; }

[dir="rtl"] .card-people-list {
  text-align: right; }
  [dir="rtl"] .card-people-list .media-body {
    margin-left: 0;
    margin-right: 15px; }

.amountCardFormat {
  font-size: 20px;
  font-weight: bold;
}

.card-turnover-per .row > div:not(:first-child)::before {
  content: '';
  position: absolute;
  top: 0;
  left: -1px;
  bottom: 0;
  border-left: 1px solid #ced4da;
}

.amountTotalCardFormat {
  font-weight: bold;
  font-size: 42px;
}

.card-turnover-total-line {
  top: -1px;
  left: 0;
  bottom: 0;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ced4da;
}

/* --------------------------------------- */
/* ############### SIGN IN ############### */
/* --------------------------------------- */
.signin-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px; }
  @media (min-width: 576px) {
    .signin-wrapper {
      padding: 30px; } }

.signin-box {
  width: 400px;
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 20px; }
  @media (min-width: 480px) {
    .signin-box {
      padding: 40px; } }
  .signin-box .slim-logo {
    margin-right: 0;
    margin-bottom: 40px; }
  .signin-box.signup {
    width: 500px; }
    .signin-box.signup .btn-signin {
      margin-bottom: 20px; }

.signin-title-primary {
  font-weight: 300;
  color: #212529; }

.signin-title-secondary {
  font-weight: 300;
  margin-bottom: 50px; }

.btn-signin {
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  border: 0;
  min-height: 40px;
  background-image: linear-gradient(to right, #1b84e7 0%, #6f42c1 100%);
  background-repeat: repeat-x; }

.signin-left,
.signin-right {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px; }
  @media (min-width: 768px) {
    .signin-left,
    .signin-right {
      width: 50%; } }
  .signin-left .signin-box,
  .signin-right .signin-box {
    border: 0;
    padding: 0; }

.signin-left .signin-box {
  background-color: transparent; }

.signin-right {
  background-color: #fff; }

.signup-separator {
  position: relative;
  text-align: center;
  margin-bottom: 20px; }
  .signup-separator::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #dee2e6; }
  .signup-separator span {
    background-color: #fff;
    padding: 0 10px;
    position: relative;
    z-index: 5; }

.btn-facebook {
  background-color: #4267B2;
  color: #fff; }

.btn-twitter {
  background-color: #1DA1F2;
  color: #fff; }

/********** RTL SUPPORT **********/
/*********************************/
[dir="rtl"] .signin-box {
  text-align: right; }



/********** CUSTOM TYMES4 **********/
/*********************************/


.action-bar {
  padding: 10px 0px;
  border-bottom: 1px solid #e5e9f2;
  text-align: right;
}

.modal-bar {

  border-bottom: 1px solid #e5e9f2;

  .modal-title {
    padding: 3px 0px 18px 0px;
  }

  .btn-grid {
    font-size: 19px;
    padding: 4px 8px 3px 9px;

    :hover {
      background-color: #F0F1F5;
    }
  }
}

.stand-alone-tabs {
  height: 40px;
}

.navbar .navbar-right .navbar-nav .user-menu .dropdown-menu {
  width: 240px;
}

.nav-profile-follow {
 i { padding: 10px 0px;}
}

.mat-option {
  font-family: "Karla", sans-serif;
  font-size: 14px;
}

.btn-grid:disabled {
  color: $gray !important;
}

.btn-icon {

  padding: 11px 14px;
  font-size: unset;
  height: 42px;
}

.modal-actions {

  text-align: right;

  .modal-actions-object {
    border-right: 1px solid #e5e9f2;
    padding-right: 10px;
  }


  div {
    display: inline;
  }

  .close {
    padding: 6px 0px 0px 15px;
  }

}

.pagination {
  .page-link {

    padding: 8px 12px;

  }
}

.brand-small {
  font-size: 15px;
  display: block;
  margin: 2px 0px 0px 5px;
}
.card-default .card-header-bg {
  height: 75px;

  &.card-header-bg--red::after{
    content: '';
    display: block;
    position: relative;
    height: 75px;
    width: 100%;
    background-color: rgba(255,0,0,0.6);
  }
}

.card-profile .card-profile-footer {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #ffffff;
}


.breadcrumb {
    margin-left: 20px;
    padding: 15px 10px 0px 20px;
    border: none;
}

.btn {
    i {
        padding-right: 10px;
    }

  }
.btn-round {
  border-radius: 50%;
}

.btn-grid {
  margin-right: 4px;
  padding: 3px 8px;


}


.navbar .navbar-right .navbar-nav .user-menu .user-image {
    width: 40px;
    height: 40px;
}

.sidebar .sub-menu {
    //padding: .625rem 0 .625rem 0.625rem;
    background-color: rgba(255,255,255, 0.04);
}

.section-title-text {
    color: $gray-100;
    font-size: 12px;
}
.table.table-email tr td {
    padding: 0.75rem .625rem;
    white-space: nowrap;
    vertical-align: middle;
}

.table.table-email tr td:not(.grid-action) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: $gray-100;
}

.section-selection {
  .btn-group {
    .btn {
      padding: 4px 5px 4px 15px;
    }

    .active {
      background-color: $dark;
    }
  }
}

// .table tbody tr:nth-of-type(odd) {
//   background-color: #eff4ff;
// }

.table th, .table td {
  border-bottom: 1px solid #f3f3f3;

}


.content {
    padding-top: 0rem;

  }

  .list-actions {
    nav {
            a {
                .nav-link {
                color: $primary !important;
                cursor: pointer;
            }
        }
    }
  }

.head-left-options {
    .form-group {
        margin-bottom: 0px;
        margin-right: 10px;
    }

    .btn-icon {
        color: #31343d;
    }
}

.form-content {

    padding-top: 20px;

    label.form-control-label {
        color: #31343d;
        font-weight: 400;
        font-size: 14px;
    }
    label {
        color: #31343d;
        font-weight: 400;
        font-size: 14px;
    }
    .tx-danger {
      color: $danger;
    }

    h6.form-header {
      color: #343a40;
      font-size: 13px;
      text-transform: uppercase;
      //padding-left: 13px;
      font-weight: 600;

      margin: 1em 0 0.5em;
      line-height: 2;
      border-bottom: 1px solid #ececec;

    }


}


.action-row {
  background-color: #fff;
}

.section-wrapper {



  .section-title {
    font-size: 1.375rem;
    font-weight: 500;
    color: #31343d;
    padding-top: 0;
    display: flex;
    padding-bottom: 1em;
  }

  p {
    color: #868ba1;
    font-size: 14px;
  }
}
ul.parsley-errors-list {
  li {
    color: red;
    font-size: 12px;
  }

}

.email-right-column .head-left-options {
    margin-bottom: 5px;
}

td.grid-select {
  padding: 5px !important;
}
td.grid-action {
  text-align: right;
  padding: 0px !important;
  width: 80px;

  div.dropdown {
    display: inline;

    .dropdown-menu {
      padding: 0px 10px;
    }

    .dropdown-item:not(:first-child) {
      border-top: solid 1px rgb(229, 233, 242);
    }
    .dropdown-item {
      cursor: pointer;
      color: $dark;
      display: block;
      padding: 8px 0px;
      i {
        margin-right: 10px;
      }
    }

    .disabled {
      color: $gray !important;
      cursor: default;
    }

  }
}
.grid-action .mdi {

    font-size: 17px;
    font-weight: bold;
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
}

.mdi:hover {
    // background-color: white !important;
    color: $dark;

}

.mdi-warning {
   // background-color:  rgba($cyan, 0.1) !important;
    color: $cyan;
  //  border-color: rgba($cyan, 0.7) !important;
}

.mdi-dark {

  background-color:  rgba($dark, 0.08) !important;
  color: $dark;
 // border-color: #8CB253 !important;
}

.mdi-info {

  background-color:  #F6F9F1 !important;
  color: #8CB253;
 // border-color: #8CB253 !important;
}

.mdi-warn {
  background-color:  rgba($warning, 0.08) !important;
  color: $warning;
 //border-color: $danger !important;
}
.mdi-danger {
  background-color:  #FFF1F2 !important;
  color: $danger;
 //border-color: $danger !important;
}

.mdi-primary {
  background-color:  #F1F5FA !important;
  color: $primary;
  //border-color: $primary !important;
}

.pagination-wrapper
{
    padding-top: 20px;
    text-align: center;
    cursor: pointer;
}
.pagination-text {
    font-size: 12px;
    padding-top: 20px;
}



.table {

    .sort-by-asc {
      left: 3px;
      display: inline-block;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      margin: 4px 4px 0 3px;
      background: 0 0;
      border-bottom: 7px solid $primary;
      border-top-width: 0;
    }

    .sort-by-desc {
      left: 3px;
      display: inline-block;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      margin: 4px 4px 0 3px;
      background: 0 0;
      border-top: 7px solid $primary;
      border-bottom-width: 0;
    }

  }


.color-picker-display {
    top: 5px;
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: solid 1px #aaa;
  }

  .mat-dialog-container {
    box-shadow: 0 2px 12px rgba(0,0,0,.5) !important;
    //padding: 18px 24px !important;
  }

  .cdk-overlay-container {
    position: fixed;
    z-index: 2000;
  }
  .cdk-global-overlay-wrapper, .cdk-overlay-container {
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .cdk-global-overlay-wrapper, .cdk-overlay-container {
    overflow-x: auto;
  }

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
      opacity: 1;
  }
  .cdk-overlay-dark-backdrop {
      background: rgba(0,0,0,.300);
  }

  .cdk-overlay-backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1000;
      pointer-events: auto;
      -webkit-tap-highlight-color: transparent;
      -webkit-transition: opacity .4s cubic-bezier(.25,.8,.25,1);
      transition: opacity .4s cubic-bezier(.25,.8,.25,1);
      opacity: 0;
  }

  .sk-folding-cube-sm  {
    margin: 30px auto;
    width: 30px;
    height: 30px;
  }


.modal-header{
  padding: 0px 0px 16px 0px;
}
  .ddholder {
    .full-width{
      width: calc(100% - 10px);
      margin-left:10px;
      &_first-child {
        width: calc(100% - 10px);
        margin-left:0 !important;
      }
      &_main {
        margin-top: 4px;
      }
    }
    i {
      padding: 16px 0px 0px 8px;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .confirmBox .mat-dialog-container {
    padding: 0px;
  }

  .confirmBox .modal-dialog {
    margin: 0px;
  }

  .confirmBox .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding: 20px 25px;
    align-items: center;
    background-color: #f8f9fa;
  }

  .confirmBox .modal-footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
    background-color: #f8f9fa;
  }

  .modal-footer > *{
    margin: 0px;
  }

  .ui-datepicker-month, .ui-datepicker-year {
    padding: 5px;
    margin: -5px 2px 0 2px;
  }

  .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before {
    content: '\F142';
  }
  .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    content: '\F141';
  }
  .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
  .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 18px;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .tab-details {
    text-align: left;
  }

  .chipbox {
    font-weight: 500;
    padding: 4px 6px !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    text-align: center !important;
    line-height: 12px !important;
    max-height: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
  }

  .chipbox-open {
    background-color: var(--danger);
    color: #eee;
  }

  .chipbox-closed {
    background-color: var(--success);
    color: #eee;
  }

  .chipbox-warning {
    background-color: var(--warning);
    color: #eee;
  }

  .chipbox-primary {
    background-color:$primary;
    color: #eee;
  }

  .chipbox-inactive {
    background-color:$gray;
    color: #eee;
  }

  .chipbox-active {
    background-color: var(--info);
    color: #eee;
  }

  // seat plan start
  .plan-holder {
    position: absolute;
    top: 165px;
    bottom: 120px;
    left: 43px;
    right: 43px;
    border: #ccc solid 1px;
    text-align: center;
  }

  .plan-holder-intab {
    height: calc(100vh - 325px);
    background-color: #fff;
  }
  .placement-sidebar {
    overflow: auto;
    &-title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;

    }
  }
  .contigent-header {
    &-title {
      font-size: 15px;
      padding-bottom: 5px;
    }
    &-details {
      text-align: center;
      padding-left: 0;
      i {
        color: red;
      }
    }
  }

  .placement-input {
    color: transparent;
    position: absolute;
    top: 0;
    left: 5px;
    background: transparent;
    border: none;
  }


.seat-plan {
    display: inline-block;
    background-color: #fff;
    display: table;
    border-spacing: 2px;
    position: relative;
    .ui-selecting { background-color: rgba(254, 203, 64, 0.3); color: #000; }
  }

  .app-key-area {
    padding: 10px;
  }

  .app-key-holder {
    background-color: #fff;
    z-index: 1000;
    display: inline;
    padding-right: 30px;
  }

  .app-key {
    font-size: 0.8rem;
    text-align: center;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: .1em .5em;
    margin: 0 .2em;
    box-shadow: 0 1px 0 rgba(0,0,0,.2), 0 0 0 2px #fff inset;
    background-color: #f7f7f7;
  }

  .app-key-lmouse {
    background-image: url('../images/left-mouse.png');
    width: 20px;
    height: 20px;
    background-size: 100%;
    display: inline-block;
  }

  .app-key-rmouse {
    width: 20px;
    height: 20px;
    background-size: 100%;
    display: inline-block;
    background-image: url('../images/right-mouse.png');
  }

  .spr {
    display: table-row;
  }

  // seatplan-holder
  .sp-selectable {}

  .sp-h {
    font-weight: bold;
    padding-top: 1px;
    display: table-cell;
    table-layout:fixed;
    overflow:hidden;
    word-wrap:break-word;

    min-width: 24px;
    max-width: 24px;
    width: 24px;

    min-height: 24px;
    max-height: 24px;
    height: 24px;
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    color: white;
  }

  .s-not-available-in-edit {
    border: solid 1px #777;
    background-color: #999; //updated from the seatplan
    color: #fff;
  }

  .s-r {
    border: solid 1px #555;
    background-color: #8EBE50;
    color: #000;
  }

  .s-free {
    border: solid 1px #555;
    background-color: #8EBE50;
    color: #000;
  }

  .s-taken {
    border: solid 1px #555;
    background-color: #ED1C24;
    color: #000;
  }

  .s-reserved {
    border: solid 1px #555;
    background-color: #666; //updated from the seatplan
    color: #fff;
  }

  .s-rv {
    border-radius: 50%;
  }

  .s-fence {
    background-image: url('../images/fence.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .s-stair {
    background-image: url('../images/stairs.png');
    background-repeat: no-repeat;
    background-position: center center;
  }

  .line-taken {
    color: #ED1C24;
  }

  .sp-sel  {
    border: solid 1px rgba(254, 203, 64, 1);
    background: rgba(254, 203, 64, 0.7) !important;
    color: #000;
  }

  .seat-details {
    font-size: 0.9em;
    padding: 20px 0px;
  }

  .seat-details-summary {
    font-size: 1.1em;
    color: white;
    font-weight: bold;
    font-size: 0.8em;
    padding: 12px 10px;
    margin-top: 14px;
  }

  .legend {
    padding: 12px 24px;
    font-size: 0.8em;
    font-style: italic;
  }

  .seat-details-undetermined {
    background-color: #999;
  }

  .seat-details-sellable {
    background-color: green;
  }

  .seat-details-unsellable {
    background-color: red;
  }

  .seat-details-total{
    font-weight: bold;
    border-top: solid 1px #333;
  }
  // seat plan end
  .ckbox-input{
    margin-top: calc(1.5em + 0.5rem + 10px);
  }

  .ui-datepicker {
    z-index: 99999 !important;
  }

  .h6-icon{
    margin: 0.5em 0 0.5em -1em;
  }

  .action-text{
    color: #1b84e7;
    cursor: pointer;

    &:hover{
      color: #115da4;
    }

    &.disabled {
      color: inherit;
      cursor: default;
    }
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .action-text{
      display: inline-block;
      border-left: 1px solid #ced4da;
      border-right: 1px solid #ced4da;
      padding: 12px;
      margin: 0 -1px;
      font-size: 12px;
    }
  }

  .bd-t {
    border-bottom: 1px solid #ced4da;
  }

  .bd-r {
    border-bottom: 1px solid #ced4da;
  }

  .bd-b {
    border-bottom: 1px solid #ced4da;
  }

  .bd-l {
    border-bottom: 1px solid #ced4da;
  }

  .chk-nolabel {
    margin-top: 31px;
  }

  .input-height{
    height: calc(1.5em + 1.188rem + 2px);
    display: flex;
    align-items: center;

    .ckbox {

      input[type=checkbox] {
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
        margin-left: 8px;
      }

    }
  }

  .clearable-picker{
    position: relative;
    &>span {
      display: flex;
      height: calc(1.5em + 1.188rem + 2px);
      align-items: center;
      position: absolute;
      bottom: 0;
      right: 15px;
      cursor: pointer;
    }
  }

  /*---- Loader ----*/
  .app-loader,
  .view-loader {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
  }
  .spinner {
      width: 40px;
      height: 40px;
      position: relative;
      margin: auto;
  }
  .double-bounce1, .double-bounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
      animation: sk-bounce 2.0s infinite ease-in-out;
  }
  .double-bounce2 {
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s;
  }
  .view-loader {
      display: block;
      padding-top: 160px;
      background: rgba(255, 255, 255, .3);
      z-index: 9999;
  }
  .view-loader * {
      margin: auto;
  }
  @-webkit-keyframes sk-bounce {
      0%, 100% { -webkit-transform: scale(0.0) }
      50% { -webkit-transform: scale(1.0) }
  }
  @keyframes sk-bounce {
      0%, 100% {
          transform: scale(0.0);
          -webkit-transform: scale(0.0);
      }
      50% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
  }

  .bounce-accent {
    background-color: #ffffff;
    color: #115da4;
  }

  .bounce-primary {
    background-color: #ffffff;
    color: #1b84e7;
  }

  .clickable {
      cursor: pointer;
  }

  // .not-clickable {
  //   cursor: pointer;
  // }

  .customer-details{
    h2 {

        font-size: 18px;
        margin: 10px 10px;

    }
  }



  .invoice-info-row {
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    padding: 1px 0;
    font-size: 14px;
    margin-bottom: 0;

    span:first-child {
      color: #495057;
      font-weight: bold;
    }
}

.important-info {

  padding: 30px 20px 20px 20px;
  border-radius: 4px;
  color: #495057;
  p.info-data{
    padding: 2px 0px 10px 0px;
  }
  p{
    text-transform: uppercase;
    font-size: 14px;
  }
  p.info-sub{
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 2px;
  }
  i {
    font-size: 28px;
    padding-top: 10px;
    color: rgba(0,0,0,0.1);
  }
  hr {
    margin-top: 0px;
  }
}

.info-highlight {
  background-color: #F0F1F5;
}
.info-label {
  font-weight: normal;
}
.info-data {
  font-weight: bold;
}

.tx-12 {
  font-size: 12px;
}
.tx-13 {
  font-size: 13px;
}
.tx-14 {
  font-size: 14px;
}

.order-overview {


  .header {
    font-weight: bold;
  }
  .details {
td {
  padding: 10px 2px;
}
  }
}

.order-overview-footer {
  font-weight: bold;
  width: 100%;
  font-size: 13px;

  td{
    padding: 10px 1px 10px 0px;
  }
}

.order-summary {
  max-height: 590px;
  overflow-y: auto;
}

.list-actions {
  text-align: right;
  padding: 10px 0px;

  .btn {
    margin-left: 5px;
  }
}

.fill {
  min-height: 100vh;
  height: 100%;
}

.valign-parent {
  position: relative;
}
// .valign-child {
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 100%;
// }

.object-actions {
  background-color: #F0F1F5;
  text-align: right;
  padding: 10px 20px;
  .btn {
    margin-left: 10px;
  }

  .separator {
    border-right: 1px solid $gray;
    padding: 5px 9px 7px 0px;
  }

}

.object-actions-in-grid {

  text-align: right;
  padding: 0px 20px;
  .btn {
    margin-left: 10px;
  }

  .separator {
    border-right: 1px solid $gray;
    padding: 5px 9px 7px 0px;
  }

}

.btn-small {
  padding: 5px 10px;
  font-size: 13px;
}

.sidebar-scrollbar {
  overflow: hidden;
}

.contingent-details {
  font-size: 11px;
  font-weight: bold;
  color: #333;
  padding: 5px 0px 5px 20px;
  font-style: italic;
}

.legend-row {
  padding: 5px 0px;

  p {
      padding-top: 2px;
      margin: 0px;
      font-size: 13px;
  }
}

.seat-row {
  font-size:13px;
  font-weight: bold;
  padding: 2px;
  width: 24px;
  height: 24px;
  background-color: #ddd;
  text-align: center;
}

.seat-row-full-field {
  position: absolute;
  left: 0px;
  right: 0px;
  font-size:17px;
  font-weight: bold;
  height: 24px;
  margin-left: 20px;
  background-color: #4F7942;
  color: #9BB398;
  text-align: center;
  margin: 0px 2px;
}


.action-selectors {
  padding-right: 5px;
  color: #999;
  cursor: pointer;


  div {
      display: inline;
  }
  i {
      padding-right: 5px;
  }
}
.selector-selected {
  //font-weight: bold;
  .keep {
      color: #76BC5E;
  }
  .new {
      color: #333;
  }
  .modify {
      color: #EE8F31;
  }
  .delete {
      color: #f44336;
  }
}

.readonly {
  color: #999;
}

.table-order-edit {
  td {
    vertical-align: middle;
  }
}

.edit-action-row {
  td, th {
    padding:10px 10px 5px 10px;
  }
}
.edit-data-row{
  td, th {
    padding: 5px 10px 10px 10px;
  }
}

.form-check-label {
  padding-left: 10px;
}


.status-dot{
  margin-top: 3px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
}
.status-dot-big{
  margin-top: 3px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
}

.status-dot-reserved {
  background-color: rgba(140,140,140,1);
}
.status-dot-unavailable {
  background-color: #bbb;
}
.status-dot-available {
  background-color: rgb(126, 181,78);
}
.status-dot-not-available {
  background-color: rgba(183,38,55,1);
}

.status-dot-indetermined
{
  background-color: #B1CAD3;
}
.status-dot-ok {
  background-color: rgb(126, 181,78);
}

.status-dot-error {
  background-color: rgba(183,38,55,1);
}

.lro {
  width: 100%;
  padding: 10px 30px;

  &-running {
      border: solid 2px rgb(250,235,204);
      background-color: rgb(252,248,227);
      color: rgb(103,81,45);
  }

  &-succes {
      border: solid 2px rgb(214,233,198);
      background-color: rgb(223,240,216);
      color: rgb(44,84,45);
  }

  &-error {
      border: solid 2px rgb(235,204,209);
      background-color: rgb(242,222,222);
      color: rgb(141,54,54);
  }
}

.grid-manage-lines {
  padding: 0px 10px 20px 0px;
  text-align: right;
  font-size: 16px;
  font-weight: bold;

  .btn-icon {
    padding: 0px;
  }

  .btn {
    margin: 2px;
  }
}

//********* JQUERY SELECTABLE**************
.ui-selectable-helper {

	  position: absolute;
	  z-index: 1000001;
    border: 2px solid #7DDAFC;
    background-color: rgba(125,218,252, 0.3);
}

//********* JQUERY SELECTABLE**************


.ql-container.ql-snow {

  padding: 0px;
}

.ql-container .ql-editor {

  min-height: unset;
}


/*---- Loader ----*/

.view-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: auto;
}
.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}
.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}
.view-loader {
    display: block;
    padding-top: 48vh;
    background: rgba(255, 255, 255, .3);
    z-index: 9999;
}
.view-loader * {
    margin: auto;
}
@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

.bounce-accent {
  background-color: #115da4;
  color: #115da4 !important;
}

.bounce-primary {
  background-color: #1b84e7;
  color: #1b84e7 !important;
}

.card-no-padding .card-body {
  padding: 0;
}

.section-selection-header {
  background-color: rgba(0, 0, 0, 0.03);
  min-height: 41px;
}

.email-right-column {
  border-left: none !important;
}

.btn-filter {

  :hover {
    color: #212529;
    background-color: #ffc107;
  }
}

.pos-total {
  background-color: #f5f6fa;
}

.map-holder {
  position: absolute;
  top: 43px; right: 0; bottom: 0; left: 0;
  display: block;
}
.seat-summary {
  width: 100%;
  tr {
    font-size: 15px;
  }
}

.table-responsive {
  overflow: inherit;
}

.card-profile img {
  position: relative;
}

.ng-select {
  width: 100%;
}

.ng-select .ng-select-container {
  padding: 0px 5px 0px 8px;
  height: 42px !important;
  border-color: #e5e9f2 !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-top: 5px;
  padding-left: 20px !important;
}

.ng-select.ng-select-focused {
  &:not(.ng-select-opened) > .ng-select-container {
      border-color: #a5c0de !important;
      box-shadow: 0 0 0 0.2rem transparent !important;
  }
}

.ng-dropdown-panel {
  z-index: 2000 !important;
}

.ng-option .picker-in-dropdown, .ng-value .picker-in-dropdown {
  float: left;
  width: 20px;
}

.ng-option .label-picker, .ng-value .label-picker {
  float: left;
  margin-left: 10px;
  margin-top: 5px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  text-align: left;
}

input::placeholder, .ng-placeholder {
  color: #d3d3d3 !important;
  opacity: 1;
  font-style: italic;
}

.paymentMethodsSelect .ng-select-container {
  height: 62px !important;
}

.paymentMethodsSelect .ng-arrow-wrapper {
  visibility: hidden;
}

.page-wrapper {
  height: 100vh;
}

div.alert  {
  i.fa {
    font-size: 18px;
    margin-right: 10px;
  }

}
.alert-warning, .alert-info  {
  color: #2f2f2f;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done .step-indicator, aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.completed .step-indicator {
  background-color: #487EBC !important;
}

aw-wizard.horizontal .wizard-steps {
  margin-top: 5px;
}

aw-wizard .form-layout-footer {
  margin-top: 20px;
}

.wizard-step-container {
  border-top: solid 1px #bbb;
  padding: 0px;
}

.wizard-buttons {

  padding: 20px 0px 20px 0px;
}

.ng-select.ng-select-multiple .ng-select-container{
  height: auto !important;
  & .ng-value-container .ng-value {
    padding: 2px 5px;
  }
}

.inactive {
  color: $gray-200;
}

.mt-checkbox {
  margin-top: 40px;
}

.inline-form {
  max-width: 800px;
}

a.file-download-link {
  font-weight: normal;
}




/* Tree */
.tree-children.tree-children-no-padding { padding-left: 0 }
.tree-children { padding-left: 20px; overflow: hidden }
.node-drop-slot { display: block; height: 2px }
.node-drop-slot.is-dragging-over { background: #ddffee; height: 20px; border: 2px dotted #888; }
.toggle-children-wrapper-expanded .toggle-children { transform: rotate(90deg) }
.toggle-children-wrapper-collapsed .toggle-children { transform: rotate(0); }
.toggle-children-wrapper {
  padding: 2px 3px 5px 1px;
}
/* tslint:disable */
.toggle-children {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABAhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ1dWlkOjY1RTYzOTA2ODZDRjExREJBNkUyRDg4N0NFQUNCNDA3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkYzRkRFQjcxODUzNTExRTU4RTQwRkQwODFEOUZEMEE3IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkYzRkRFQjcwODUzNTExRTU4RTQwRkQwODFEOUZEMEE3IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDQyAyMDE1IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTk5NzA1OGEtZDI3OC00NDZkLWE4ODgtNGM4MGQ4YWI1NzNmIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6YzRkZmQxMGMtY2NlNS0xMTc4LWE5OGQtY2NkZmM5ODk5YWYwIi8+IDxkYzp0aXRsZT4gPHJkZjpBbHQ+IDxyZGY6bGkgeG1sOmxhbmc9IngtZGVmYXVsdCI+Z2x5cGhpY29uczwvcmRmOmxpPiA8L3JkZjpBbHQ+IDwvZGM6dGl0bGU+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+5iogFwAAAGhJREFUeNpiYGBgKABigf///zOQg0EARH4A4gZyDIIZ8B/JoAJKDIDhB0CcQIkBRBtEyABkgxwoMQCGD6AbRKoBGAYxQgXIBRuZGKgAKPIC3QLxArnRSHZCIjspk52ZKMrOFBUoAAEGAKnq593MQAZtAAAAAElFTkSuQmCC');
  height: 8px;
  width: 9px;
  background-size: contain;
  display: inline-block;
  position: relative;
  top: 1px;
  background-repeat: no-repeat;
  background-position: center;
}
.toggle-children-placeholder {
  display: inline-block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 1px;
  padding-right: 3px;
}
.node-content-wrapper {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 2px;
  transition: background-color .15s,box-shadow .15s;
}
.node-wrapper {display: flex; align-items: flex-start;}
.node-content-wrapper-active,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  background: #beebff;
}
.node-content-wrapper-focused { background: #e7f4f9 }
.node-content-wrapper:hover { background: #f7fbff }
.node-content-wrapper-active, .node-content-wrapper-focused, .node-content-wrapper:hover {
  box-shadow: inset 0 0 1px #999;
}
.node-content-wrapper.is-dragging-over { background: #ddffee; box-shadow: inset 0 0 1px #999; }
.node-content-wrapper.is-dragging-over-disabled { opacity: 0.5 }

tree-viewport {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  overflow: auto;
  display: block;
}
.tree-children { padding-left: 20px }
.empty-tree-drop-slot .node-drop-slot { height: 20px; min-width: 100px }
.angular-tree-component {
  width: 100%;
  position:relative;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* IE/Edge */
  user-select: none;           /* non-prefixed version, currently not supported by any browser */
}

tree-root .angular-tree-component-rtl {
  direction: rtl;
}
tree-root .angular-tree-component-rtl .toggle-children-wrapper-collapsed .toggle-children {
  transform: rotate(180deg) !important;
}
tree-root .angular-tree-component-rtl .tree-children {
  padding-right: 20px;
  padding-left: 0;
}

tree-node-checkbox {
  padding: 1px;
}

/* end tree */

.btn-icon-small {
  padding-right: 0px;
}

/* LISTVIEW */

.form-control-filter {
  height: 31px !important;
}

.mat-error {

  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6;

  .mat-button {
    color: #721c24;
  }
}


/* notification center */

.dropdown-notify {
  display: block;
  width: 320px;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgb(28 29 36 / 10%), inset 0px -1px 0px 0px rgb(229 230 235 / 10%);
  background-color: #ffffff;
}

.badge-xs {
  background-color: #fd5190 !important;
}


/* Archiving styling */

.sidebar-minified .archiving .left-sidebar .app-brand .brand-name {
  visibility: visible;
  opacity: 1;
}

.archiving {
  .app-brand {
    background: #000;
    height: 75px;
    line-height: 75px;

    .app-brand-archief {
      color: #FFF;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      i {
        margin: 0 1rem 0 1.5rem;
      }
    }
  }
  .left-sidebar {
    background-image: none !important;
    background-color: #FFF;

    &::before {
      display: none;
    }

    .sidebar .sidebar-inner {

      .section-title {
        color: #555555;
      }

      > li {
        > a {
          color: #686868;
        }

        &.expand {
          background-color: #E5E5E5;

          .clickable {
            background-color: #7D7D7D;
            color: #FFF;
          }

          .sub-menu > li {
            &.active {
              font-weight: 700;
            }

            > a {
              color: #555555;
            }
          }
        }

        &.active > a {
          background-color: #7D7D7D;
          color: #FFF;
        }
      }
    }
  }

  .card-header-bg {
    background: #aeaeae !important;
  }
}
